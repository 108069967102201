
    .featured-category {
        .featured-category-headline {
            background: $cfourSecondaryColor;
        }
        &:hover {
            .featured-category-headline {
                background: $cfourSecondaryColor;
            }
        }
    }



.furniture {
    .featured-category {
        .featured-category-headline {
            background: $cfourFurnitureSecondaryColor;
        }
        &:hover {
            .featured-category-headline {
                background: $cfourFurnitureSecondaryColor;
            }
        }
    }
}
