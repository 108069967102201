@charset "UTF-8";
header .navbar .container-max > .row {
  flex-direction: column; }

html nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li ul.nav-dropdown-inner {
  background: transparent; }

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px; }

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff; }

.list-controls .btn-link {
  color: #fff !important; }

.no-touch nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li:hover, .touch nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li.hover {
  background: #fff !important;
  color: #062e47 !important; }

@media (min-width: 992px) {
  .main-navbar-collapsable ul.mainmenu > li > ul > li > a {
    width: 100%; } }

.cmp-address-list .dropdown-menu.show {
  transform: translate3d(0px, 114px, 0px) !important; }

#page-header-parent > .container-max.cfour-cachet {
  top: 0 !important;
  position: relative !important; }

.back-to-top {
  margin: 0;
  position: fixed;
  bottom: 2rem;
  right: 5rem;
  width: 40px;
  height: 40px;
  z-index: 100;
  display: none;
  text-decoration: none;
  opacity: 0.5;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  border: 1px solid #282d2f;
  background: #4D4F4D;
  border-radius: .1rem;
  cursor: pointer;
  text-align: center; }
  .back-to-top:hover {
    opacity: 1; }
  .back-to-top i {
    font-size: 29px;
    color: #f7f7f9; }

.footer .to-top {
  display: block;
  background-color: #999999;
  text-align: center;
  padding: .5rem 1rem;
  text-decoration: none !important;
  cursor: pointer;
  width: 100%;
  color: #fff;
  font-size: 1.5rem; }

.furniture .no-touch nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li:hover, .furniture .touch nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li.hover {
  color: #3E382C !important; }

.card .item-edit .fa {
  color: #a2b9c9;
  cursor: pointer; }
  .card .item-edit .fa:hover {
    color: #a2b9c9;
    border-color: #a2b9c9; }

.furniture .card .item-edit .fa {
  color: #504839;
  cursor: pointer; }
  .furniture .card .item-edit .fa:hover {
    color: #504839;
    border-color: #504839; }

.page-content {
  background: #fff; }

.fashion-pro .btn-outline-primary {
  color: #062e47;
  border-color: #a2b9c9; }
  .fashion-pro .btn-outline-primary:focus, .fashion-pro .btn-outline-primary.focus {
    color: #062e47;
    background-color: #82a1b6;
    border-color: #577b95; }
  .fashion-pro .btn-outline-primary:hover {
    color: #062e47;
    background-color: #82a1b6;
    border-color: #7b9cb3; }
  .fashion-pro .btn-outline-primary:active, .fashion-pro .btn-outline-primary.active,
  .open > .fashion-pro .btn-outline-primary.dropdown-toggle {
    color: #062e47;
    background-color: #82a1b6;
    border-color: #7b9cb3; }
    .fashion-pro .btn-outline-primary:active:hover, .fashion-pro .btn-outline-primary:active:focus, .fashion-pro .btn-outline-primary:active.focus, .fashion-pro .btn-outline-primary.active:hover, .fashion-pro .btn-outline-primary.active:focus, .fashion-pro .btn-outline-primary.active.focus,
    .open > .fashion-pro .btn-outline-primary.dropdown-toggle:hover,
    .open > .fashion-pro .btn-outline-primary.dropdown-toggle:focus,
    .open > .fashion-pro .btn-outline-primary.dropdown-toggle.focus {
      color: #062e47;
      background-color: #6b90a9;
      border-color: #577b95; }
  .fashion-pro .btn-outline-primary:active, .fashion-pro .btn-outline-primary.active,
  .open > .fashion-pro .btn-outline-primary.dropdown-toggle {
    background-image: none; }
  .fashion-pro .btn-outline-primary.disabled:hover, .fashion-pro .btn-outline-primary.disabled:focus, .fashion-pro .btn-outline-primary.disabled.focus, .fashion-pro .btn-outline-primary[disabled]:hover, .fashion-pro .btn-outline-primary[disabled]:focus, .fashion-pro .btn-outline-primary[disabled].focus,
  fieldset[disabled] .fashion-pro .btn-outline-primary:hover,
  fieldset[disabled] .fashion-pro .btn-outline-primary:focus,
  fieldset[disabled] .fashion-pro .btn-outline-primary.focus {
    background-color: #a2b9c9;
    border-color: #a2b9c9; }

.fashion-pro .btn-primary {
  background-color: #a2b9c9;
  border-color: #a2b9c9;
  color: #062e47; }
  .fashion-pro .btn-primary:focus, .fashion-pro .btn-primary.focus {
    color: #062e47;
    background-color: #82a1b6;
    border-color: #577b95; }
  .fashion-pro .btn-primary:hover {
    color: #062e47;
    background-color: #82a1b6;
    border-color: #7b9cb3; }
  .fashion-pro .btn-primary:active, .fashion-pro .btn-primary.active,
  .open > .fashion-pro .btn-primary.dropdown-toggle {
    color: #062e47;
    background-color: #82a1b6;
    border-color: #7b9cb3; }
    .fashion-pro .btn-primary:active:hover, .fashion-pro .btn-primary:active:focus, .fashion-pro .btn-primary:active.focus, .fashion-pro .btn-primary.active:hover, .fashion-pro .btn-primary.active:focus, .fashion-pro .btn-primary.active.focus,
    .open > .fashion-pro .btn-primary.dropdown-toggle:hover,
    .open > .fashion-pro .btn-primary.dropdown-toggle:focus,
    .open > .fashion-pro .btn-primary.dropdown-toggle.focus {
      color: #062e47;
      background-color: #6b90a9;
      border-color: #577b95; }
  .fashion-pro .btn-primary:active, .fashion-pro .btn-primary.active,
  .open > .fashion-pro .btn-primary.dropdown-toggle {
    background-image: none; }
  .fashion-pro .btn-primary.disabled:hover, .fashion-pro .btn-primary.disabled:focus, .fashion-pro .btn-primary.disabled.focus, .fashion-pro .btn-primary[disabled]:hover, .fashion-pro .btn-primary[disabled]:focus, .fashion-pro .btn-primary[disabled].focus,
  fieldset[disabled] .fashion-pro .btn-primary:hover,
  fieldset[disabled] .fashion-pro .btn-primary:focus,
  fieldset[disabled] .fashion-pro .btn-primary.focus {
    background-color: #a2b9c9;
    border-color: #a2b9c9; }

.furniture .btn-outline-primary {
  color: #3E382C;
  border-color: #504839; }
  .furniture .btn-outline-primary:focus, .furniture .btn-outline-primary.focus {
    color: #3E382C;
    background-color: #322d24;
    border-color: #060504; }
  .furniture .btn-outline-primary:hover {
    color: #3E382C;
    background-color: #322d24;
    border-color: #2c2820; }
  .furniture .btn-outline-primary:active, .furniture .btn-outline-primary.active,
  .open > .furniture .btn-outline-primary.dropdown-toggle {
    color: #3E382C;
    background-color: #322d24;
    border-color: #2c2820; }
    .furniture .btn-outline-primary:active:hover, .furniture .btn-outline-primary:active:focus, .furniture .btn-outline-primary:active.focus, .furniture .btn-outline-primary.active:hover, .furniture .btn-outline-primary.active:focus, .furniture .btn-outline-primary.active.focus,
    .open > .furniture .btn-outline-primary.dropdown-toggle:hover,
    .open > .furniture .btn-outline-primary.dropdown-toggle:focus,
    .open > .furniture .btn-outline-primary.dropdown-toggle.focus {
      color: #3E382C;
      background-color: #1d1a15;
      border-color: #060504; }
  .furniture .btn-outline-primary:active, .furniture .btn-outline-primary.active,
  .open > .furniture .btn-outline-primary.dropdown-toggle {
    background-image: none; }
  .furniture .btn-outline-primary.disabled:hover, .furniture .btn-outline-primary.disabled:focus, .furniture .btn-outline-primary.disabled.focus, .furniture .btn-outline-primary[disabled]:hover, .furniture .btn-outline-primary[disabled]:focus, .furniture .btn-outline-primary[disabled].focus,
  fieldset[disabled] .furniture .btn-outline-primary:hover,
  fieldset[disabled] .furniture .btn-outline-primary:focus,
  fieldset[disabled] .furniture .btn-outline-primary.focus {
    background-color: #504839;
    border-color: #504839; }

.furniture .btn-primary {
  background-color: #504839;
  border-color: #504839;
  color: #fff; }
  .furniture .btn-primary:focus, .furniture .btn-primary.focus {
    color: #3E382C;
    background-color: #322d24;
    border-color: #060504; }
  .furniture .btn-primary:hover {
    color: #fff;
    background-color: #322d24;
    border-color: #2c2820; }
  .furniture .btn-primary:active, .furniture .btn-primary.active,
  .open > .furniture .btn-primary.dropdown-toggle {
    color: #3E382C;
    background-color: #322d24;
    border-color: #2c2820; }
    .furniture .btn-primary:active:hover, .furniture .btn-primary:active:focus, .furniture .btn-primary:active.focus, .furniture .btn-primary.active:hover, .furniture .btn-primary.active:focus, .furniture .btn-primary.active.focus,
    .open > .furniture .btn-primary.dropdown-toggle:hover,
    .open > .furniture .btn-primary.dropdown-toggle:focus,
    .open > .furniture .btn-primary.dropdown-toggle.focus {
      color: #3E382C;
      background-color: #1d1a15;
      border-color: #060504; }
  .furniture .btn-primary:active, .furniture .btn-primary.active,
  .open > .furniture .btn-primary.dropdown-toggle {
    background-image: none; }
  .furniture .btn-primary.disabled:hover, .furniture .btn-primary.disabled:focus, .furniture .btn-primary.disabled.focus, .furniture .btn-primary[disabled]:hover, .furniture .btn-primary[disabled]:focus, .furniture .btn-primary[disabled].focus,
  fieldset[disabled] .furniture .btn-primary:hover,
  fieldset[disabled] .furniture .btn-primary:focus,
  fieldset[disabled] .furniture .btn-primary.focus {
    background-color: #504839;
    border-color: #504839; }

body.fashion-pro {
  background-color: #fff;
  color: #062e47; }
  body.fashion-pro a {
    outline: none;
    color: #28424f; }
    body.fashion-pro a:hover {
      color: #062e47; }
  body.fashion-pro a.text-primary {
    outline: none;
    color: #28424f !important; }
    body.fashion-pro a.text-primary:hover {
      color: #062e47 !important; }
  body.fashion-pro .form-control:focus {
    border-color: #28424f; }

body.furniture {
  color: #3E382C; }
  body.furniture a {
    outline: none;
    color: #ED6345; }
    body.furniture a:hover {
      color: #3E382C; }
  body.furniture a.text-primary {
    outline: none;
    color: #ED6345 !important; }
    body.furniture a.text-primary:hover {
      color: #3E382C !important; }
  body.furniture .form-control:focus {
    border-color: #ED6345; }
  body.furniture .footer .footer-features {
    background: #dadae3; }
    body.furniture .footer .footer-features i {
      color: #3E382C !important; }

.container-max > .cmp.cmp-hero > .row {
  width: 100%;
  margin: 0; }

#page-body .single .carousel-control:hover i {
  color: #062e47; }

@media (min-width: 768px) {
  #page-body .single .carousel-control.right {
    margin-right: 5px; } }

#page-body .single .CfourArticleHeader {
  text-transform: uppercase; }

#page-body .single hr {
  border-top: 1px solid #062e47; }

#page-body .single .CfourArticleTable {
  width: 100%; }

#page-body .single .producerimage img {
  max-width: 100%; }

#page-body .single .single-description {
  font-size: 14px; }

#page-body .single .input-unit {
  width: 33.333%;
  float: left; }
  #page-body .single .input-unit .custom-select {
    padding: 1.2rem 1rem .4rem;
    font-weight: bold;
    color: #062e47; }

#page-body .single label {
  font-weight: bold; }

#page-body .single .crossprice del {
  color: #a2b9c9;
  font-size: 24px; }

#page-body .single .price .vat {
  color: #062e47;
  font-weight: normal;
  font-size: 10px; }
  #page-body .single .price .vat a {
    text-decoration: underline;
    color: inherit; }

#page-body .single .price.h1 {
  font-weight: bold;
  font-size: 40px;
  color: #062e47; }

@media (max-width: 991px) and (min-width: 768px) {
  #page-body .single .price {
    float: left !important; } }

#page-body .single .CfourAddToBasket .CfourQuantityInput input {
  font-weight: bold;
  color: #062e47;
  border-color: #999999; }

#page-body .single .CfourAddToBasket .CfourQuantityInput .input-group-btn button {
  border-color: #999999; }

#page-body .single .CfourAddToBasket .CfourAddToBasketButton {
  background: #a2b9c9;
  border: #a2b9c9;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9em;
  padding: .7em 0; }

#page-body .single .CfourAddToBasket .CfourAddToBasketButton:hover {
  background: #b3cd16;
  border: #b3cd16; }

#page-body .single .add-to-wish-list {
  color: #062e47;
  font-weight: bold; }
  #page-body .single .add-to-wish-list .fa-heart {
    padding: 7.5px;
    border: 1px solid #cccccc;
    color: #062e47;
    font-size: 16px;
    margin-right: 15px; }

#page-body .single .add-to-wish-list:hover .fa-heart {
  color: #062e47 !important; }

#page-body .nav-tabs {
  border-bottom: 1px solid #062e47; }
  #page-body .nav-tabs .nav-link {
    border: none;
    color: #062e47; }
  #page-body .nav-tabs .nav-link:hover {
    background: transparent;
    color: #062e47; }
  #page-body .nav-tabs .nav-link.active {
    background: #fff;
    border-top: 1px solid #062e47;
    border-left: 1px solid #062e47;
    border-right: 1px solid #062e47;
    margin-top: -1px;
    margin-left: -1px;
    margin-right: -1px; }

/* --- Furniture Color Palette --- */
.furniture #page-body .single .carousel-control:hover i {
  color: #ED6345; }

.furniture #page-body .single hr {
  border-top: 1px solid #dee2e6; }

.furniture #page-body .single .input-unit .custom-select {
  color: #3E382C; }

.furniture #page-body .single .crossprice del {
  color: #504839; }

.furniture #page-body .single .price .vat {
  color: #3E382C; }

.furniture #page-body .single .price.h1 {
  color: #3E382C; }

.furniture #page-body .single .CfourAddToBasket .CfourQuantityInput input {
  color: #3E382C; }

.furniture #page-body .single .CfourAddToBasket .CfourAddToBasketButton {
  background: #504839;
  border: #504839; }

.furniture #page-body .single .CfourAddToBasket .CfourAddToBasketButton:hover {
  background: #b3cd16;
  border: #b3cd16; }

.furniture #page-body .single .add-to-wish-list {
  color: #3E382C; }
  .furniture #page-body .single .add-to-wish-list .fa-heart {
    border: 1px solid #cccccc;
    color: #3E382C; }

.furniture #page-body .single .add-to-wish-list:hover .fa-heart {
  color: #3E382C !important; }

.furniture #page-body .nav-tabs {
  border-bottom: 1px solid #3E382C; }
  .furniture #page-body .nav-tabs .nav-link {
    color: #3E382C; }
  .furniture #page-body .nav-tabs .nav-link:hover {
    color: #3E382C; }
  .furniture #page-body .nav-tabs .nav-link.active {
    border-top: 1px solid #3E382C;
    border-left: 1px solid #3E382C;
    border-right: 1px solid #3E382C; }

a {
  color: #062e47; }

@media (min-width: 991px) {
  #page-header .top-bar .navbar-navigation {
    padding: 0; } }

#page-header .top-bar .cfour-cachet {
  position: relative; }
  #page-header .top-bar .cfour-cachet .cachet {
    position: absolute;
    left: 0;
    z-index: 9999;
    top: 0;
    background: #fff;
    box-shadow: 0 3px 17px rgba(0, 0, 0, 0.1);
    padding: 15px 0 15px 15px;
    width: auto;
    height: 110px; }
    #page-header .top-bar .cfour-cachet .cachet .image {
      width: 80px;
      height: 80px;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      display: inline-block !important;
      margin-right: 15px; }
    #page-header .top-bar .cfour-cachet .cachet img {
      border-style: none;
      width: 100px;
      padding-right: 15px; }

#page-header .top-bar .controls-list .control-user #login-change > .dropdown .dropdown-menu a {
  background-color: #fff; }

#page-header .top-bar .controls-list .control-user #login-change > .dropdown.open a {
  background-color: #fff; }

@media (max-width: 767px) {
  #page-header .top-bar .controls-list .control-user #login-change a[href="#login"] span {
    display: none; }
  #page-header .top-bar .controls-list .control-user #login-change a[href="#registration"] span {
    display: none; } }

#page-header .top-bar .controls-list .control-basket {
  position: relative; }
  #page-header .top-bar .controls-list .control-basket .toggle-basket-preview {
    background-color: #fff; }
    #page-header .top-bar .controls-list .control-basket .toggle-basket-preview:hover {
      background-color: #fff; }
  #page-header .top-bar .controls-list .control-basket #basket-tooltip {
    position: absolute;
    z-index: 999;
    right: 20px;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s; }
    #page-header .top-bar .controls-list .control-basket #basket-tooltip .tooltipArrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      float: right;
      margin-right: 10px; }
    #page-header .top-bar .controls-list .control-basket #basket-tooltip .tooltipContent {
      padding: 4px 20px;
      margin-top: 10px;
      font-size: 16px;
      color: #fff;
      white-space: nowrap; }
  #page-header .top-bar .controls-list .control-basket #basket-tooltip.cfourFadeOut {
    opacity: 0; }

#page-header .top-bar .controls-list li a {
  font-size: .9em;
  text-transform: uppercase; }

#page-header .top-bar .controls-list li a[aria-expanded="true"] {
  font-size: .9em;
  text-transform: uppercase; }

#page-header .top-bar .controls-list > li a {
  font-size: .9em;
  text-transform: uppercase;
  min-height: 30px;
  outline: none; }

#page-header .top-bar .controls-list > li a[aria-expanded="true"] {
  font-size: .9em;
  text-transform: uppercase; }

#page-header .navbar {
  background: #fff;
  border-bottom: none;
  flex-direction: column;
  align-items: normal; }
  #page-header .navbar .brand-wrapper {
    position: relative;
    transform: none;
    text-align: center;
    display: flex; }
    #page-header .navbar .brand-wrapper .navbar-brand {
      display: block;
      margin: 0 auto;
      max-width: 200px;
      padding: 20px 0; }
      @media (max-width: 767px) {
        #page-header .navbar .brand-wrapper .navbar-brand {
          padding: 10px 0; } }
      #page-header .navbar .brand-wrapper .navbar-brand img {
        max-height: none; }
    #page-header .navbar .brand-wrapper .navbar-toggler {
      position: absolute;
      margin-top: 2.5rem;
      right: 1rem; }

#page-header .sb-header .brand-wrapper {
  width: 100%; }

@media (max-width: 354px) {
  .control-basket i {
    display: none; }
  .control-wish-list .badge-right {
    display: none; } }

@media (max-width: 991px) {
  #page-header .top-bar .cfour-cachet .cachet {
    top: 0px;
    padding: 7px 0 7px 7px;
    width: auto;
    height: 55px; }
    #page-header .top-bar .cfour-cachet .cachet .image {
      width: 40px;
      height: 40px; }
    #page-header .top-bar .cfour-cachet .cachet img {
      border-style: none;
      width: 40px;
      padding-right: 10px; } }

@media (max-width: 991px) {
  .hasCachetOffset #searchBox {
    top: 85px; }
  div:not(.top-bar) > .page-header > div > .cmp.cmp-search-box .search-box-inner {
    margin: 0 auto;
    justify-content: end; }
  .search-box-shadow-frame {
    padding: 5px;
    background: #e9ecef; } }

@media (min-width: 992px) {
  div:not(.top-bar) > .page-header > div > .cmp.cmp-search-box .search-box-inner {
    margin: 0 auto;
    justify-content: end; }
  .search-box-shadow-frame {
    max-width: 100%;
    padding: 5px;
    background: #e9ecef; }
  .hasCachetOffset .search-box-shadow-frame {
    max-width: 50%; } }

@media (max-width: 575px) {
  #page-header .top-bar .cfour-cachet .cachet {
    position: relative; } }

.furniture a {
  color: #3E382C; }

.furniture #page-header .top-bar {
  background: #3E382C; }
  .furniture #page-header .top-bar .controls-list .control-user #login-change > .dropdown .dropdown-menu a {
    color: #3E382C; }
  .furniture #page-header .top-bar .controls-list .control-user #login-change > .dropdown.open a {
    color: #3E382C; }
  .furniture #page-header .top-bar .controls-list .control-languages a[aria-expanded="true"] {
    background: transparent; }
  .furniture #page-header .top-bar .controls-list .control-search a[aria-expanded="true"] {
    background: #504839; }
  .furniture #page-header .top-bar .controls-list .control-wish-list a i.fa-heart {
    color: #3E382C; }
  .furniture #page-header .top-bar .controls-list .control-basket .toggle-basket-preview {
    color: #fff;
    background: #ED6345; }
  .furniture #page-header .top-bar .controls-list .control-basket #basket-tooltip .tooltipArrow {
    border-color: transparent transparent #3E382C transparent; }
  .furniture #page-header .top-bar .controls-list .control-basket #basket-tooltip .tooltipContent {
    background: #3E382C; }
  .furniture #page-header .top-bar .controls-list li a:hover {
    background: #504839;
    color: #3E382C; }
  .furniture #page-header .top-bar .controls-list li a[aria-expanded="true"] {
    background: #504839;
    color: #3E382C; }
  .furniture #page-header .top-bar .controls-list > li a:hover {
    color: #fff;
    background: transparent; }
  .furniture #page-header .top-bar .controls-list > li a[aria-expanded="true"] {
    background: #504839;
    color: #3E382C; }

@media (min-width: 992px) {
  .furniture .cmp.cmp-search-box .search-box-shadow-frame {
    background: #3E382C; }
  .furniture .cmp.cmp-search-box .search-submit {
    background: #3E382C;
    color: #fff !important; } }

.fashion-pro a {
  color: #062e47; }

.fashion-pro #page-header .top-bar {
  background: #a2b9c9; }
  .fashion-pro #page-header .top-bar .controls-list .control-user #login-change > .dropdown .dropdown-menu a {
    color: #062e47; }
  .fashion-pro #page-header .top-bar .controls-list .control-user #login-change > .dropdown.open a {
    color: #062e47; }
  .fashion-pro #page-header .top-bar .controls-list .control-languages a[aria-expanded="true"] {
    background: #a2b9c9; }
  .fashion-pro #page-header .top-bar .controls-list .control-search a[aria-expanded="true"] {
    background: #a2b9c9; }
  .fashion-pro #page-header .top-bar .controls-list .control-wish-list a i.fa-heart {
    color: #062e47; }
  .fashion-pro #page-header .top-bar .controls-list .control-basket .toggle-basket-preview {
    color: #062e47; }
  .fashion-pro #page-header .top-bar .controls-list .control-basket #basket-tooltip .tooltipArrow {
    border-color: transparent transparent #062e47 transparent; }
  .fashion-pro #page-header .top-bar .controls-list .control-basket #basket-tooltip .tooltipContent {
    background: #062e47; }
  .fashion-pro #page-header .top-bar .controls-list li a {
    color: #062e47; }
    .fashion-pro #page-header .top-bar .controls-list li a:hover {
      background: #a2b9c9;
      color: #062e47; }
  .fashion-pro #page-header .top-bar .controls-list li a[aria-expanded="true"] {
    background: #a2b9c9;
    color: #062e47; }
  .fashion-pro #page-header .top-bar .controls-list > li a {
    color: #062e47; }
    .fashion-pro #page-header .top-bar .controls-list > li a:hover {
      background: #a2b9c9;
      color: #062e47; }
  .fashion-pro #page-header .top-bar .controls-list > li a[aria-expanded="true"] {
    background: #a2b9c9;
    color: #062e47; }

.footer {
  font-size: 14px;
  font-weight: 300;
  background: #fff; }
  .footer .c4footerentries {
    text-transform: uppercase; }
    .footer .c4footerentries > .my-4 {
      border-color: #062e47; }
  .footer .c4footerheadline {
    border-bottom: 1px solid #999999;
    padding-bottom: 12px;
    display: block; }
  .footer a {
    font-weight: bold;
    color: #062e47; }
  .footer .c4footerbox {
    padding: 15px 0; }
    @media (max-width: 767px) {
      .footer .c4footerbox {
        padding: 1.25rem; } }
    .footer .c4footerbox li {
      list-style: none; }
    .footer .c4footerbox li.c4footershoppinglink {
      padding-bottom: 10px; }
    .footer .c4footerbox .c4footertag {
      float: left;
      border: 1px solid #efefef;
      padding: 0 7px;
      margin-right: 5px;
      margin-bottom: 5px; }
      .footer .c4footerbox .c4footertag > * {
        font-size: 16px;
        font-weight: normal; }
  .footer .c4footercontact i {
    font-size: 20px;
    margin-right: 5px; }
  .footer .c4footercontact a {
    font-weight: 300; }
  .footer .c4footeremail {
    text-transform: lowercase; }
  .footer .c4footersocialmedia li {
    display: inline-block;
    list-style: none; }
    .footer .c4footersocialmedia li img {
      max-width: 35px; }
    .footer .c4footersocialmedia li span.a img {
      max-width: 35px; }
    .footer .c4footersocialmedia li > span img {
      margin-top: -1px; }
  .footer .c4footersocialmedia .a img {
    max-width: 35px; }
  .footer .c4footerPayments .a img {
    max-width: 35px; }
  .footer .c4footerPayments a {
    display: inline-flex; }
    .footer .c4footerPayments a img {
      height: 35px;
      max-width: 100px; }
  .footer .back-to-top-center {
    background: #a2b9c9;
    bottom: -5px;
    width: 50px;
    right: 50px;
    border: none;
    opacity: 1;
    border-radius: 0;
    left: auto; }
    .footer .back-to-top-center i {
      width: 50px; }
  .footer .c4footerabout {
    text-transform: none;
    white-space: pre-line; }
  .footer .c4footerAboutUs {
    padding-top: 20px; }
    .footer .c4footerAboutUs .cfourfootersvg {
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      max-width: 200px;
      margin-bottom: 10px; }
  .footer .c4footerShopping {
    padding-top: 20px; }
    .footer .c4footerShopping .c4footershopping a {
      padding-bottom: 10px;
      display: inline-block; }
  .footer .c4footerInformation {
    padding-top: 20px; }
    .footer .c4footerInformation a {
      display: inline-block;
      padding-bottom: 10px; }
  .footer .c4footersymbol {
    padding-right: 18px; }
  .footer .back-to-top {
    background: #062e47; }
  @media (min-width: 768px) {
    .footer .accordion {
      display: flex;
      flex-flow: wrap; }
      .footer .accordion .card.c4footer {
        flex: 0 0 31%;
        max-width: 31%;
        margin: .5rem;
        border: none; }
        .footer .accordion .card.c4footer .card-header {
          background: transparent;
          border-bottom: 1px solid #999999;
          pointer-events: none; }
          .footer .accordion .card.c4footer .card-header a {
            padding: 1rem 0; }
            .footer .accordion .card.c4footer .card-header a:after {
              display: none; }
        .footer .accordion .card.c4footer .collapse {
          display: block; }
          .footer .accordion .card.c4footer .collapse .card-body {
            padding: .5rem 0; } }
  .footer .accordion > .card > .card-header {
    padding: 0; }
    .footer .accordion > .card > .card-header a {
      width: 100%;
      padding: 1rem;
      text-align: left; }
      .footer .accordion > .card > .card-header a.btn-header-link:after {
        content: "\f106";
        font-family: "FontAwesome";
        font-weight: 900;
        float: right; }
      .footer .accordion > .card > .card-header a.btn-header-link.collapsed:after {
        content: "\f107";
        font-family: "FontAwesome";
        font-weight: 900;
        float: right; }
  .footer .accordion .card-body li {
    list-style: none; }
    .footer .accordion .card-body li.c4footershoppinglink {
      padding-bottom: 10px; }
  @media (min-width: 768px) {
    .footer .footer-block-mobile {
      display: block; } }
  @media (max-width: 767px) {
    .footer .footer-block-desktop {
      display: none; }
    .footer .footer-block-mobile {
      display: block; } }

#c4copyright {
  background-color: #d9dad5;
  height: auto;
  padding: 22px; }
  #c4copyright .container-max.c4footercopyright a {
    color: #062e47;
    font-weight: bold; }
  #c4copyright .c4footerPayments i {
    font-size: 22px;
    padding-right: 30px; }
  #c4copyright .c4footerPayments .paymentlink {
    margin-right: 30px; }
    #c4copyright .c4footerPayments .paymentlink i {
      padding-right: 0; }
  #c4copyright .c4footerPayments ul {
    padding: 0; }
    #c4copyright .c4footerPayments ul li {
      list-style: none;
      display: inline-block; }
      #c4copyright .c4footerPayments ul li a {
        padding: 10px 0;
        display: block; }
        #c4copyright .c4footerPayments ul li a img {
          max-width: 35px; }
      #c4copyright .c4footerPayments ul li img {
        max-width: 35px; }

.furniture .footer .c4footerentries > .my-4 {
  border-color: #3E382C; }

.furniture .footer .c4footerheadline {
  border-bottom: 1px solid #3E382C; }

.furniture .footer a {
  color: #3E382C; }

.furniture .footer .back-to-top-center {
  background: #504839; }

.furniture .footer .back-to-top {
  background: #3E382C; }

.furniture #c4copyright .container-max.c4footercopyright a {
  color: #3E382C; }

.main-navbar-collapsable {
  background: #efefef;
  border-top: 1px solid #a2b9c9;
  border-bottom: 1px solid #a2b9c9; }
  .main-navbar-collapsable ul.mainmenu {
    color: #062e47;
    text-align: center;
    float: none !important; }
    .main-navbar-collapsable ul.mainmenu li {
      float: none; }
      .main-navbar-collapsable ul.mainmenu li:hover {
        background-color: transparent !important;
        color: #062e47 !important; }
        .main-navbar-collapsable ul.mainmenu li:hover > a {
          color: #062e47 !important; }
      .main-navbar-collapsable ul.mainmenu li > ul {
        background: #fff; }
        .main-navbar-collapsable ul.mainmenu li > ul > li {
          font-weight: bold;
          color: #062e47; }
          .main-navbar-collapsable ul.mainmenu li > ul > li:hover {
            color: #062e47 !important; }
      .main-navbar-collapsable ul.mainmenu li a {
        color: #062e47; }
    .main-navbar-collapsable ul.mainmenu li.ddown .level1 {
      border: none; }
      .main-navbar-collapsable ul.mainmenu li.ddown .level1 > a {
        color: #062e47 !important;
        text-transform: uppercase;
        border-bottom: 1px solid;
        margin-bottom: 10px;
        width: 100%; }
    .main-navbar-collapsable ul.mainmenu li.ddown .level2 {
      font-weight: normal;
      border: none; }
      .main-navbar-collapsable ul.mainmenu li.ddown .level2:hover {
        background: #fff !important; }

nav.navbar.megamenu .main-navbar-collapsable li.ddown > ul {
  border: 1px solid #a2b9c9; }

nav.navbar.megamenu .collapse {
  min-width: auto;
  width: 1200px;
  margin: auto;
  right: 0; }

.furniture .normalmenu ul.collapse, .fashion-pro .normalmenu ul.collapse {
  margin-top: 1px; }

@media (min-width: 992px) {
  nav.navbar.megamenu {
    position: relative; } }

@media (min-width: 992px) {
  nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu {
    justify-content: center; }
    nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li:hover {
      color: #062e47 !important; }
    nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li.level1 > a {
      color: #062e47; }
    nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li a {
      color: #062e47; }
      nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li a:hover {
        color: #062e47 !important; } }

.mobile-navigation {
  color: #062e47;
  background: #fff; }
  .mobile-navigation ul.breadcrumb {
    background: #a2b9c9;
    color: #fff; }

.furniture .main-navbar-collapsable {
  background: #dbd7cc;
  -webkit-box-shadow: inset 0 5px 10px 1px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 10px 40px -15px rgba(0, 0, 0, 0.3);
  border: 0; }
  .furniture .main-navbar-collapsable ul.mainmenu {
    color: #3E382C; }
    .furniture .main-navbar-collapsable ul.mainmenu li {
      float: none; }
      .furniture .main-navbar-collapsable ul.mainmenu li:hover {
        color: #3E382C !important; }
      .furniture .main-navbar-collapsable ul.mainmenu li > ul > li {
        color: #3E382C; }
        .furniture .main-navbar-collapsable ul.mainmenu li > ul > li:hover {
          color: #3E382C !important; }
      .furniture .main-navbar-collapsable ul.mainmenu li a {
        color: #3E382C; }
    .furniture .main-navbar-collapsable ul.mainmenu li.ddown .level1 > a {
      color: #3E382C !important; }

.furniture nav.navbar.megamenu .main-navbar-collapsable li.ddown > ul {
  border: 1px solid #504839; }

@media (min-width: 992px) {
  .furniture nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li:hover {
    color: #3E382C !important; }
  .furniture nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li.level1 > a {
    color: #3E382C; }
  .furniture nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li a {
    color: #3E382C; }
    .furniture nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li a:hover {
      color: #3E382C !important; } }

.furniture .mobile-navigation {
  color: #3E382C; }
  .furniture .mobile-navigation ul.breadcrumb {
    background: #504839; }

.top-bar .cmp.cmp-search-box {
  background-color: transparent;
  max-width: 1200px;
  margin: auto;
  z-index: 99999; }
  .top-bar .cmp.cmp-search-box .search-submit {
    color: #062e47; }
  .top-bar .cmp.cmp-search-box .search-box-inner {
    display: flex;
    flex-flow: row-reverse; }
    .top-bar .cmp.cmp-search-box .search-box-inner .search-box-shadow-frame {
      padding: 0 0 0 7px;
      max-width: 50%;
      display: flex;
      flex: 100;
      border-top: 1px solid #fff; }
      .top-bar .cmp.cmp-search-box .search-box-inner .search-box-shadow-frame > .container-max {
        padding: 0; }

.furniture .top-bar .cmp.cmp-search-box .search-submit {
  color: #3E382C; }

.top-bar .cmp-country-settings {
  left: 0;
  right: 0;
  transform: unset;
  background-color: #a2b9c9;
  border-top: 1px solid #fff;
  overflow: hidden;
  z-index: 9999; }
  .top-bar .cmp-country-settings .list-title {
    color: #fff; }
    .top-bar .cmp-country-settings .list-title hr {
      border-color: #fff; }
  .top-bar .cmp-country-settings ul li.active a {
    background-color: #fff;
    color: #062e47; }
    .top-bar .cmp-country-settings ul li.active a:hover {
      background-color: #fff;
      color: #062e47; }
  .top-bar .cmp-country-settings ul li a:hover {
    background-color: #fff;
    color: #062e47; }

.top-bar .cmp.cmp-currency-select {
  background-color: #a2b9c9;
  border-top: 1px solid #fff;
  overflow: hidden;
  z-index: 9999; }
  .top-bar .cmp.cmp-currency-select hr {
    border-color: #fff; }
  .top-bar .cmp.cmp-currency-select ul li a:hover, .top-bar .cmp.cmp-currency-select ul li.active a {
    color: #062e47;
    background-color: #fff; }
  @media (max-width: 767px) {
    .top-bar .cmp.cmp-currency-select .currency-list ul > li {
      width: 25%; } }

.furniture .top-bar .cmp-country-settings {
  background-color: #504839; }
  .furniture .top-bar .cmp-country-settings ul li.active a {
    color: #3E382C; }
    .furniture .top-bar .cmp-country-settings ul li.active a:hover {
      color: #3E382C; }
  .furniture .top-bar .cmp-country-settings ul li a:hover {
    color: #3E382C; }

.furniture .top-bar .cmp.cmp-currency-select {
  background-color: #504839; }
  .furniture .top-bar .cmp.cmp-currency-select ul li a:hover, .furniture .top-bar .cmp.cmp-currency-select ul li.active a {
    color: #3E382C; }

nav .breadcrumb {
  padding: 5px 0;
  background: transparent; }
  nav .breadcrumb a {
    color: #062e47; }

.furniture nav .breadcrumb .breadcrumb-item {
  color: #ED6345; }
  .furniture nav .breadcrumb .breadcrumb-item.active {
    color: #999999; }

html .sidebar-categories .category-title {
  /*color: #062e47;*/
  font-size: 1.2rem;
  padding-left: 1.2rem; }

html .sidebar-categories > ul > li.active > a > .collapse > ul > li.active > a {
  background: #efefef !important; }

html .sidebar-categories > ul > .nav-item > .nav-link {
  padding: 1rem 1rem 1rem 1.2rem; }

html .sidebar-categories > ul > .nav-item > .collapse > ul > li > a {
  padding: 1rem 1rem 1rem 2rem; }

html .sidebar-categories > ul > .nav-item > .collapse > ul > li.active > a {
  background: #a2b9c9;
  color: #fff; }

html .sidebar-categories > ul > .nav-item > .collapse > ul > li > .collapse > ul > li > a {
  padding: 1rem 1rem 1rem 3rem !important; }

html .sidebar-categories > ul > .nav-item > .collapse > ul > li > .collapse > ul > li.active > a {
  background: #a2b9c9;
  color: #fff; }

html .sidebar-categories > ul > .nav-item > .collapse > ul > li > .collapse > ul > li > .collapse > ul > li > a {
  padding: 1rem 1rem 1rem 4rem !important; }

html .sidebar-categories ul li {
  /*&.active {
        & > a {
          background: #efefef !important;
        }
      }*/ }
  html .sidebar-categories ul li a {
    padding: 1.05rem 15px; }
    html .sidebar-categories ul li a.toggle {
      position: absolute;
      background: 0 0 !important;
      height: 35px;
      display: block;
      vertical-align: middle;
      right: 5px;
      padding: 1rem 15px .75rem !important;
      margin-top: -48px !important;
      border: none; }
    html .sidebar-categories ul li a:hover {
      color: #000 !important; }
  html .sidebar-categories ul li ul {
    display: block; }
    html .sidebar-categories ul li ul li a {
      /*color: #ababab;*/ }
    html .sidebar-categories ul li ul li.active > a {
      /*color: #000 !important;*/ }
      html .sidebar-categories ul li ul li.active > a .fa {
        color: #fff; }
    html .sidebar-categories ul li ul li .collapse.show ul li a {
      padding: 1rem 0 !important;
      /*margin-left: 21px;*/
      /*color: #062e47;*/ }
      @media (min-width: 992px) {
        html .sidebar-categories ul li ul li .collapse.show ul li a {
          /*max-width: 91%;*/ } }
      html .sidebar-categories ul li ul li .collapse.show ul li a.toggle {
        margin-top: -48px;
        /*margin-right: 15px;*/ }

/*p.sidebar-menu-title {
  padding: 15px;
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: $cfourWhite;
}*/
.cmp-product-thumb {
  border: 1px solid #efefef;
  background-color: transparent;
  padding-bottom: 15px; }
  .cmp-product-thumb .thumb-background, .cmp-product-thumb .main-image, .cmp-product-thumb .thumb-content {
    background-color: transparent; }
  .cmp-product-thumb .add-to-basket-lg-container {
    border-color: transparent #062e47 transparent transparent; }
    .cmp-product-thumb .add-to-basket-lg-container > i {
      color: #fff; }
    .cmp-product-thumb .add-to-basket-lg-container:hover {
      border-color: transparent #062e47 transparent transparent; }
      .cmp-product-thumb .add-to-basket-lg-container:hover > i {
        color: #fff; }
  .cmp-product-thumb .prices {
    display: block;
    text-align: right; }
    .cmp-product-thumb .prices .crossprice {
      color: #a2b9c9;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.1; }
    .cmp-product-thumb .prices .price {
      color: #062e47;
      font-weight: 900;
      font-size: 20px;
      text-align: right; }
    .cmp-product-thumb .prices .price-col {
      padding: 0;
      left: 50%; }
    .cmp-product-thumb .prices .crossprice-dummy {
      height: 22px; }
  .cmp-product-thumb .vat-porto-info, .cmp-product-thumb .vat {
    text-align: right;
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    color: #062e47 !important; }
    .cmp-product-thumb .vat-porto-info > a, .cmp-product-thumb .vat > a {
      color: #062e47;
      text-decoration: underline; }
  .cmp-product-thumb .special-tags {
    padding: 18px; }
    .cmp-product-thumb .special-tags .special-tag, .cmp-product-thumb .special-tags .tag {
      border-radius: 5px;
      padding: 5px;
      box-shadow: none;
      position: relative; }
      .cmp-product-thumb .special-tags .special-tag.bg-danger, .cmp-product-thumb .special-tags .tag.bg-danger {
        background-color: #FFCBFF !important;
        color: #062e47 !important; }
        .cmp-product-thumb .special-tags .special-tag.bg-danger > i, .cmp-product-thumb .special-tags .tag.bg-danger > i {
          padding: 5px 7px; }
        .cmp-product-thumb .special-tags .special-tag.bg-danger::after, .cmp-product-thumb .special-tags .tag.bg-danger::after {
          border-color: transparent #ffcbff transparent; }
      .cmp-product-thumb .special-tags .special-tag.bg-primary, .cmp-product-thumb .special-tags .tag.bg-primary {
        background-color: #C4DCFE !important;
        color: #fff !important; }
        .cmp-product-thumb .special-tags .special-tag.bg-primary > i, .cmp-product-thumb .special-tags .tag.bg-primary > i {
          padding: 5px 6px; }
        .cmp-product-thumb .special-tags .special-tag.bg-primary::after, .cmp-product-thumb .special-tags .tag.bg-primary::after {
          border-color: transparent #C4DCFE transparent; }
      .cmp-product-thumb .special-tags .special-tag.bg-success, .cmp-product-thumb .special-tags .tag.bg-success {
        background-color: #30f292 !important;
        color: #fff !important; }
        .cmp-product-thumb .special-tags .special-tag.bg-success > i, .cmp-product-thumb .special-tags .tag.bg-success > i {
          padding: 2px 12px;
          font-size: 31px; }
        .cmp-product-thumb .special-tags .special-tag.bg-success::after, .cmp-product-thumb .special-tags .tag.bg-success::after {
          border-color: transparent #30f292 transparent; }
      .cmp-product-thumb .special-tags .special-tag > i, .cmp-product-thumb .special-tags .tag > i {
        font-size: 25px; }
      .cmp-product-thumb .special-tags .special-tag::after, .cmp-product-thumb .special-tags .tag::after {
        content: "";
        display: block;
        position: absolute;
        right: 8px;
        bottom: -12px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 12px 0; }
  .cmp-product-thumb .thumb-title {
    font-size: 12px;
    text-transform: uppercase;
    color: #062e47; }
    .cmp-product-thumb .thumb-title > small {
      text-transform: none;
      font-size: 100%;
      line-height: 30px;
      display: inline-block;
      margin-bottom: 10px; }
  @media (max-width: 1199px) {
    .cmp-product-thumb .price, .cmp-product-thumb .crossprice {
      font-size: 16px; } }
  @media (max-width: 991px) {
    .cmp-product-thumb .mobile-icon-right {
      float: none;
      margin-right: 10px; } }
  @media (max-width: 575px) {
    .cmp-product-thumb .price, .cmp-product-thumb .crossprice {
      font-size: 14px;
      min-height: unset;
      line-height: 20px; } }
  @media (max-width: 543px) {
    .cmp-product-thumb .special-tags {
      padding: 0; }
      .cmp-product-thumb .special-tags .tag {
        padding: 0; }
        .cmp-product-thumb .special-tags .tag > i {
          font-size: 18px; }
        .cmp-product-thumb .special-tags .tag::after {
          right: 6px;
          bottom: -7px;
          border-width: 0 10px 7px 0; }
        .cmp-product-thumb .special-tags .tag.bg-success > i {
          padding: 4px 11px;
          font-size: 20px; } }

.furniture .cmp-product-thumb .add-to-basket-lg-container {
  border-color: transparent #ED6345 transparent transparent; }
  .furniture .cmp-product-thumb .add-to-basket-lg-container:hover {
    border-color: transparent #3E382C transparent transparent; }

.furniture .cmp-product-thumb .prices .crossprice {
  color: #ED6345; }

.furniture .cmp-product-thumb .prices .price {
  color: #ED6345; }

.furniture .cmp-product-thumb .special-tags .special-tag.bg-danger, .furniture .cmp-product-thumb .special-tags .tag.bg-danger {
  background-color: #DEDFCF !important;
  color: #3E382C !important; }
  .furniture .cmp-product-thumb .special-tags .special-tag.bg-danger::after, .furniture .cmp-product-thumb .special-tags .tag.bg-danger::after {
    border-color: transparent #DEDFCF transparent; }

.furniture .cmp-product-thumb .special-tags .special-tag.bg-primary, .furniture .cmp-product-thumb .special-tags .tag.bg-primary {
  background-color: #ED6345 !important;
  color: #fff !important; }
  .furniture .cmp-product-thumb .special-tags .special-tag.bg-primary::after, .furniture .cmp-product-thumb .special-tags .tag.bg-primary::after {
    border-color: transparent #ED6345 transparent; }

.furniture .cmp-product-thumb .special-tags .special-tag.bg-success, .furniture .cmp-product-thumb .special-tags .tag.bg-success {
  background-color: #3E382C !important;
  color: #fff !important; }
  .furniture .cmp-product-thumb .special-tags .special-tag.bg-success::after, .furniture .cmp-product-thumb .special-tags .tag.bg-success::after {
    border-color: transparent #3E382C transparent; }

.furniture .cmp-product-thumb .vat-porto-info, .furniture .cmp-product-thumb .vat {
  color: #3E382C !important; }
  .furniture .cmp-product-thumb .vat-porto-info > a, .furniture .cmp-product-thumb .vat > a {
    color: #3E382C; }

.furniture .cmp-product-thumb .special-tags .special-tag.bg-danger, .furniture .cmp-product-thumb .special-tags .tag.bg-danger {
  color: #3E382C !important; }

.furniture .cmp-product-thumb .thumb-title {
  color: #3E382C; }

.featured-category .featured-category-headline {
  background: #a2b9c9; }

.featured-category:hover .featured-category-headline {
  background: #a2b9c9; }

.furniture .featured-category .featured-category-headline {
  background: #504839; }

.furniture .featured-category:hover .featured-category-headline {
  background: #504839; }

.featured-sale-new .featured-category-inner {
  background-color: rgba(162, 185, 201, 0.9); }

.featured-sale-new:hover .featured-category-inner {
  background: #a2b9c9; }

.furniture .featured-sale-new .featured-category-inner {
  background-color: rgba(80, 72, 57, 0.9); }

.furniture .featured-sale-new:hover .featured-category-inner {
  background: #504839; }

.fashion-pro .anicon > .anicon-search-item:before {
  background: #062e47; }

.fashion-pro .anicon > .anicon-search-item:after {
  border: 1px solid #062e47;
  background: #a2b9c9; }

.fashion-pro .anicon.anicon-search-xcross[aria-expanded="false"]:hover > .anicon-search-item:after {
  background: #a2b9c9; }

.fashion-pro .anicon.anicon-search-xcross[aria-expanded="true"] > .anicon-search-item:before {
  background: #062e47; }

.fashion-pro .anicon.anicon-search-xcross[aria-expanded="true"] > .anicon-search-item:after {
  background: #062e47; }

.furniture .anicon > .anicon-search-item:before {
  background: #fff; }

.furniture .anicon > .anicon-search-item:after {
  border: 1px solid #fff;
  background: #504839; }

.furniture .anicon.anicon-search-xcross[aria-expanded="false"]:hover > .anicon-search-item:after {
  background: #504839; }

.furniture .anicon.anicon-search-xcross[aria-expanded="true"] > .anicon-search-item:before {
  background: #fff; }

.furniture .anicon.anicon-search-xcross[aria-expanded="true"] > .anicon-search-item:after {
  background: #fff; }

.add-item .fa {
  color: #fff;
  border-color: #fff;
  margin-left: 0;
  margin-right: 6px; }
  .add-item .fa:hover {
    color: #fff;
    border-color: #fff; }

.cmp-address-list .item-content {
  color: #000; }

.cmp-address-list .item-remove .btn, .cmp-address-list .item-edit .btn {
  padding: 5px; }

.cmp-address-list .item input:checked + .item-inner {
  background-color: #fff;
  color: #fff; }

.list-controls .list-controls-inner {
  background: #a2b9c9; }

.cmp-paginator .page-item.active .page-link {
  background-color: #a2b9c9;
  border-color: #a2b9c9; }

.furniture .list-controls .list-controls-inner {
  background: #e3e7eb; }

.furniture .pagination .page-item.active .page-link {
  background-color: #504839;
  border-color: #504839; }

.loading-animation > div {
  background-color: #a2b9c9; }

.furniture .loading-animation > div {
  background-color: #504839; }

body > #vue-app {
  background-color: #fff; }

.btn.btn-right, .btn > .fa {
  float: none; }

.cmp-hero .hero-main .carousel .carousel-item .hero-category img {
  width: 100%; }

.cmp-hero .hero-main .carousel .carousel-item .hero-category h2 {
  background: rgba(162, 185, 201, 0.8) !important; }

.cmp-hero .hero-main .carousel .carousel-item:hover h2 {
  background: #a2b9c9 !important; }

.cmp-hero .hero-main .carousel .carousel-indicators li {
  border-color: #062e47; }
  .cmp-hero .hero-main .carousel .carousel-indicators li.active {
    background: #062e47; }

.cmp-hero .hero-main .carousel .carousel-control span:before {
  color: #062e47; }

.hero-xtras .hero-category {
  margin-bottom: 14px; }
  .hero-xtras .hero-category .hero-wrapper {
    position: relative; }
    .hero-xtras .hero-category .hero-wrapper img {
      width: 100%; }
    .hero-xtras .hero-category .hero-wrapper .hero-category-inner {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(162, 185, 201, 0.9);
      -moz-transition: background-color 0.4s;
      -webkit-transition: background-color 0.4s;
      transition: background-color 0.4s; }
      .hero-xtras .hero-category .hero-wrapper .hero-category-inner:hover {
        background-color: #a2b9c9; }
      .hero-xtras .hero-category .hero-wrapper .hero-category-inner .hero-category-headline {
        margin: .5rem;
        color: #fff; }

#page-body .home {
  /* ---- grid-item ---- */ }
  #page-body .home .section-header {
    background: transparent;
    padding: 5px 0;
    border-bottom: 1px solid #efefef;
    margin-bottom: 30px !important; }
    #page-body .home .section-header h2 {
      color: #999999;
      font-weight: 400;
      font-size: 16px; }
    #page-body .home .section-header a.section-link-all {
      color: #999999;
      right: 0; }
      #page-body .home .section-header a.section-link-all:hover {
        color: #999999; }
  #page-body .home .nav-tabs {
    border-bottom: 1px solid #efefef; }
    #page-body .home .nav-tabs .nav-item {
      margin: 0; }
      #page-body .home .nav-tabs .nav-item .nav-link {
        color: #a2b9c9;
        border: none;
        text-transform: uppercase;
        padding: 5px 20px;
        font-size: 20px; }
      #page-body .home .nav-tabs .nav-item .nav-link:first-child {
        padding-left: 0; }
      #page-body .home .nav-tabs .nav-item .nav-link.active {
        color: #062e47; }
    @media (max-width: 767px) {
      #page-body .home .nav-tabs .nav-item {
        width: 100%;
        text-align: center; } }
  #page-body .home .tab-content {
    margin-top: 20px; }
  #page-body .home .sale-new .featured-sale-new > img {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; }
  @media (max-width: 767px) {
    #page-body .home .cfourgrid {
      display: block;
      height: auto !important; }
    #page-body .home .cfourgrid > * {
      position: static !important;
      width: auto !important;
      height: auto !important;
      float: none !important; }
    #page-body .home .cfourgrid .grid-item .grid-content img {
      position: static !important; } }
  #page-body .home .cfourproductslider {
    margin-bottom: 25px; }
    #page-body .home .cfourproductslider .cfourproductslider-item {
      padding: 0 15px; }
    #page-body .home .cfourproductslider .slick-prev,
    #page-body .home .cfourproductslider .slick-next {
      background-color: #062e47; }
  #page-body .home .c4HomepageHeadline {
    margin-bottom: 20px; }
    #page-body .home .c4HomepageHeadline h2 {
      text-transform: uppercase;
      text-align: center; }
    #page-body .home .c4HomepageHeadline hr {
      width: 175px;
      border-color: #062e47; }
    #page-body .home .c4HomepageHeadline a {
      color: inherit; }
  #page-body .home .c4brands {
    width: 100%; }
    #page-body .home .c4brands .c4brandsimages.c4grayscalebrands img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%); }
    #page-body .home .c4brands .c4brandsimages .c4brandlogo {
      position: relative;
      height: 150px;
      margin-bottom: 20px; }
      #page-body .home .c4brands .c4brandsimages .c4brandlogo img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto; }
    #page-body .home .c4brands .c4textafterbrands {
      margin-top: 20px; }
  #page-body .home .cfourgrid {
    max-width: none;
    width: 100%; }
    #page-body .home .cfourgrid:after {
      content: '';
      clear: both;
      display: table; }
    #page-body .home .cfourgrid.widget-cfour-grid {
      margin-left: 0; }
    #page-body .home .cfourgrid .grid-sizer {
      width: 25%; }
    #page-body .home .cfourgrid .grid-item {
      width: 25%;
      margin-bottom: 20px;
      height: 150px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      float: left; }
      #page-body .home .cfourgrid .grid-item:after {
        content: '';
        display: block;
        clear: both; }
      #page-body .home .cfourgrid .grid-item .grid-content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative; }
        #page-body .home .cfourgrid .grid-item .grid-content img {
          width: 100%;
          position: absolute;
          top: -9999px;
          bottom: -9999px;
          left: -9999px;
          right: -9999px;
          margin: auto; }
    #page-body .home .cfourgrid .grid-item--width2 {
      width: 50%; }
    #page-body .home .cfourgrid .grid-item--width3 {
      width: 75%; }
    #page-body .home .cfourgrid .grid-item--width4 {
      width: 100%; }
    #page-body .home .cfourgrid .grid-item--height2 {
      height: 300px; }
    #page-body .home .cfourgrid .grid-item--height3 {
      height: 450px; }
    #page-body .home .cfourgrid .grid-item--height4 {
      height: 600px; }
    @media (max-width: 1199px) {
      #page-body .home .cfourgrid .grid-item {
        height: 122px; }
      #page-body .home .cfourgrid .grid-item--height2 {
        height: 244px; }
      #page-body .home .cfourgrid .grid-item--height3 {
        height: 366px; }
      #page-body .home .cfourgrid .grid-item--height4 {
        height: 488px; } }
    @media (max-width: 991px) {
      #page-body .home .cfourgrid .grid-item {
        height: 92px; }
      #page-body .home .cfourgrid .grid-item--height2 {
        height: 184px; }
      #page-body .home .cfourgrid .grid-item--height3 {
        height: 276px; }
      #page-body .home .cfourgrid .grid-item--height4 {
        height: 368px; } }

#page-body .CfourParallax {
  height: 400px;
  margin-bottom: 50px;
  margin-top: 30px; }
  #page-body .CfourParallax .category-title {
    color: #fff;
    text-shadow: 0 0 0.1em rgba(0, 0, 0, 0.75);
    font-size: 500%;
    margin-bottom: 1.5rem;
    margin-left: 20px;
    letter-spacing: 1px;
    word-wrap: break-word; }

.furniture .cmp-hero .hero-main .carousel .carousel-item .hero-category h2 {
  background: rgba(80, 72, 57, 0.8) !important; }

.furniture .cmp-hero .hero-main .carousel .carousel-item:hover h2 {
  background: #504839 !important; }

.furniture .cmp-hero .hero-main .carousel .carousel-indicators li {
  border-color: #3E382C; }
  .furniture .cmp-hero .hero-main .carousel .carousel-indicators li.active {
    background: #3E382C; }

.furniture .cmp-hero .hero-main .carousel .carousel-control span:before {
  color: #3E382C; }

.furniture .hero-xtras .hero-category .hero-wrapper .hero-category-inner {
  background-color: rgba(80, 72, 57, 0.9); }
  .furniture .hero-xtras .hero-category .hero-wrapper .hero-category-inner:hover {
    background-color: #504839; }

.furniture #page-body .home .nav-tabs .nav-item .nav-link {
  color: #504839; }

.furniture #page-body .home .nav-tabs .nav-item .nav-link.active {
  color: #3E382C; }

.furniture #page-body .home .cfourproductslider .slick-prev,
.furniture #page-body .home .cfourproductslider .slick-next {
  background-color: #3E382C; }

.furniture #page-body .home .c4HomepageHeadline hr {
  border-color: #3E382C; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: 100%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border-radius: 40px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'FontAwesome';
  font-size: 20px;
  line-height: 1;
  margin-left: 2px;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 15px; }

[dir='rtl'] .slick-prev {
  right: 15px;
  left: auto; }

.slick-prev:before {
  content: '\f067'; }

[dir='rtl'] .slick-prev:before {
  content: '\f067'; }

.slick-next {
  right: 15px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: 15px; }

.slick-next:before {
  content: '\f067'; }

[dir='rtl'] .slick-next:before {
  content: '\f067'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }
