
	.card{
		.item-edit{
			.fa{
				color: $cfourSecondaryColor;
				cursor: pointer;
				&:hover{
					color: $cfourSecondaryColor;
					border-color: $cfourSecondaryColor;
				}
			}
		}
	}

.furniture {
	.card{
		.item-edit{
			.fa{
				color: $cfourFurnitureSecondaryColor;
				cursor: pointer;
				&:hover{
					color: $cfourFurnitureSecondaryColor;
					border-color: $cfourFurnitureSecondaryColor;
				}
			}
		}
	}
}
