.top-bar {
    .cmp-country-settings {
        left: 0;
        right: 0;
        transform: unset;
        background-color: $cfourSecondaryColor;
        border-top: 1px solid $cfourWhite;
        overflow: hidden;
        z-index: 9999;
        .list-title {
            color: $cfourWhite;
            hr {
                border-color: $cfourWhite;
            }
        }
        ul{
            li.active {
                a {
                    background-color:$cfourWhite;
                    color: $cfourPrimaryColor;
                    &:hover {
                        background-color:$cfourWhite;
                        color: $cfourPrimaryColor;
                    }
                }
            }
            li {
                a {
                    &:hover {
                        background-color:$cfourWhite;
                        color: $cfourPrimaryColor;
                    }
                }
            }
        }
    }
    .cmp.cmp-currency-select {
        background-color: $cfourSecondaryColor;
        border-top: 1px solid $cfourWhite;
        overflow: hidden;
        z-index: 9999;
        hr {
            border-color: $cfourWhite;
        }
        ul li a:hover, ul li.active a {
            color: $cfourPrimaryColor;
            background-color: $cfourWhite;
        }
        @media (max-width: 767px) {
            .currency-list ul>li {
                width: 25%;
            }
        }
    }
}

.furniture {
    .top-bar {
        .cmp-country-settings {

            background-color: $cfourFurnitureSecondaryColor;
            ul{
                li.active {
                    a {
                        color: $cfourFurniturePrimaryColor;
                        &:hover {
                            color: $cfourFurniturePrimaryColor;
                        }
                    }
                }
                li {
                    a {
                        &:hover {
                            color: $cfourFurniturePrimaryColor;
                        }
                    }
                }
            }
        }
        .cmp.cmp-currency-select {
            background-color: $cfourFurnitureSecondaryColor;
            ul li a:hover, ul li.active a {
                color: $cfourFurniturePrimaryColor;
            }
        }
    }
}