.loading-animation {
    &> div {
        background-color: $cfourSecondaryColor;
    }
}

.furniture {
    .loading-animation {
        &> div {
            background-color: $cfourFurnitureSecondaryColor;
        }
    }
}