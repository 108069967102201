.footer {
    font-size: 14px;
    font-weight: 300;
    background: $cfourWhite;
    .c4footerentries {
        text-transform: uppercase;

        & > .my-4 {
            border-color: $cfourPrimaryColor;
        }
    }
    .c4footerheadline {
        border-bottom: 1px solid $cfourGrayFont;
        padding-bottom: 12px;
        display: block;
    }
    a {
        font-weight: bold;
        color: $cfourPrimaryColor;
    }
    .c4footerbox {
        padding: 15px 0;
        @media (max-width: 767px) {
            padding: 1.25rem;
        }

        li {
            list-style: none;
        }
        li.c4footershoppinglink {
            padding-bottom: 10px;
        }
        .c4footertag {
            float: left;
            border: 1px solid #efefef;
            padding: 0 7px;
            margin-right: 5px;
            margin-bottom: 5px;
            & > *{
                font-size: 16px;
                font-weight: normal;
            }
        }
    }
    .c4footercontact {
      i {
          font-size: 20px;
          margin-right: 5px;
      }
        a {
            font-weight: 300;
        }
    }
    .c4footeremail {
      text-transform: lowercase;
    }
    .c4footersocialmedia {
        li {
            display: inline-block;
            list-style: none;
            img {
                max-width: 35px;
            }
            span.a img {
                max-width: 35px;
            }
            & > span {
                img {
                    margin-top: -1px;
                }
            }
        }
    }
    .c4footersocialmedia .a img { max-width: 35px; }
    .c4footerPayments .a img { max-width: 35px; }
    .c4footerPayments {
        a {
            display: inline-flex;
            img {
                height: 35px;
                max-width: 100px;
            }
        }
    }
    .back-to-top-center {
        background: $cfourSecondaryColor;
        bottom: -5px;
        width: 50px;
        right: 50px;
        border: none;
        opacity: 1;
        border-radius: 0;
        left: auto;
        i {
            width: 50px;
        }
    }
    .c4footerabout {
        text-transform: none;
        white-space: pre-line;
    }
    .c4footerAboutUs {
        padding-top: 20px;
        .cfourfootersvg{
            position: relative;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            max-width: 200px;
            margin-bottom: 10px;
        }
    }
    .c4footerShopping {
        padding-top: 20px;
        .c4footershopping {
            a {
                padding-bottom: 10px;
                display: inline-block;
            }
        }
    }
    .c4footerInformation {
        padding-top: 20px;
        a {
            display: inline-block;
            padding-bottom: 10px;
        }
    }
    .c4footersymbol{
        padding-right: 18px;
    }
    .back-to-top {
        background: $cfourPrimaryColor;
    }
    .accordion {
        @media (min-width: 768px) {
            display: flex;
            flex-flow: wrap;
            .card.c4footer {
                flex: 0 0 31%;
                max-width: 31%;
                margin: .5rem;
                border: none;
                .card-header {
                    background: transparent;
                    border-bottom: 1px solid $cfourGrayFont;
                    pointer-events: none;
                    a {
                        padding: 1rem 0;
                        &:after {
                            display: none;
                        }
                    }
                }
                .collapse {
                    display: block;
                    .card-body {
                        padding: .5rem 0;
                    }
                }
            }
        }
        & >.card>.card-header {
            padding: 0;
            a {
                width: 100%;
                padding: 1rem;
                text-align: left;
                &.btn-header-link {
                    &:after {
                        content: "\f106";
                        font-family: "FontAwesome";
                        font-weight: 900;
                        float: right;
                    }
                    &.collapsed:after {
                        content: "\f107";
                        font-family: "FontAwesome";
                        font-weight: 900;
                        float: right;
                    }
                }
            }
        }
        .card-body {
            li {
                list-style: none;
                &.c4footershoppinglink  {
                    padding-bottom: 10px;
                }
            }
        }
    }
    @media (min-width: 768px) {
        .footer-block-mobile {
            display: block;
        }
    }
    @media (max-width: 767px) {
        .footer-block-desktop {
            display: none;
        }
        .footer-block-mobile {
            display: block;
        }
    }
}
#c4copyright{
    background-color: #d9dad5;
    height: auto;
    padding: 22px;
    .container-max.c4footercopyright a {
        color: $cfourPrimaryColor;
        font-weight: bold;
    }
    .c4footerPayments{
        i {
            font-size: 22px;
            padding-right: 30px;
        }
        .paymentlink {
            margin-right: 30px;
            i {
                padding-right: 0;
            }
        }
        ul {
            padding: 0;
            li {
                list-style: none;
                display: inline-block;
                a {
                    padding: 10px 0;
                    display: block;
                    img {
                        max-width: 35px;
                    }
                }
                img {
                    max-width: 35px;
                }
            }
        }
    }
}

.furniture {
    .footer {
        .c4footerentries {
            & > .my-4 {
                border-color: $cfourFurniturePrimaryColor;
            }
        }
        .c4footerheadline {
            border-bottom: 1px solid $cfourFurniturePrimaryColor;
        }
        a {
            color: $cfourFurniturePrimaryColor;
        }
        .back-to-top-center {
            background: $cfourFurnitureSecondaryColor;
        }
        .back-to-top {
            background: $cfourFurniturePrimaryColor;
        }
    }
    #c4copyright{
        .container-max.c4footercopyright a {
            color: $cfourFurniturePrimaryColor;
        }
    }
}
