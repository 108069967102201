body {
	&.fashion-pro {
		background-color: $cfourWhite;
		color: $cfourPrimaryColor;
		a {
			outline: none;
			color: $cfourTertiaryColor;

			&:hover {
				color: $cfourPrimaryColor;
			}
		}

		a.text-primary {
			outline: none;
			color: $cfourTertiaryColor !important;

			&:hover {
				color: $cfourPrimaryColor !important;
			}
		}

		.form-control:focus {
			border-color: $cfourTertiaryColor;
		}
	}
	&.furniture {
		color: $cfourFurniturePrimaryColor;
		a {
			outline: none;
			color: $cfourFurnitureTertiaryColor;

			&:hover {
				color: $cfourFurniturePrimaryColor;
			}
		}

		a.text-primary {
			outline: none;
			color: $cfourFurnitureTertiaryColor !important;

			&:hover {
				color: $cfourFurniturePrimaryColor !important;
			}
		}

		.form-control:focus {
			border-color: $cfourFurnitureTertiaryColor;
		}
		.footer {
			.footer-features {
				background: #dadae3;
				i {
					color: $cfourFurniturePrimaryColor !important;
				}
			}
		}
	}
}

.container-max > .cmp.cmp-hero > .row {
	width: 100%;
	margin: 0;
}
