

header .navbar .container-max > .row {
  flex-direction: column;
}
html nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li ul.nav-dropdown-inner {
  background: transparent;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}
.list-controls .btn-link {
  color: #fff !important;
}

.no-touch nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li:hover, .touch nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li.hover {
  background: #fff !important;
  color: $cfourPrimaryColor !important;
}
@media (min-width: 992px) {
  .main-navbar-collapsable ul.mainmenu > li > ul > li > a {
    width: 100%;
  }
}

.cmp-address-list .dropdown-menu.show {
  transform: translate3d(0px, 114px, 0px) !important;
}

#page-header-parent > .container-max.cfour-cachet {
  top: 0 !important;
  position: relative !important;
}

.back-to-top {
  margin: 0;
  position: fixed;
  bottom: 2rem;
  right: 5rem;
  width: 40px;
  height: 40px;
  z-index: 100;
  display: none;
  text-decoration: none;
  opacity: 0.5;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  border: 1px solid #282d2f;
  background: #4D4F4D;
  border-radius: .1rem;
  cursor: pointer;
  text-align: center;
  &:hover {
    opacity: 1;
  }
   i {
    font-size: 29px;
    color: #f7f7f9;
  }
}

.footer .to-top {
  display: block;
  background-color: $cfourGrayFont;
  text-align: center;
  padding: .5rem 1rem;
  text-decoration: none!important;
  cursor: pointer;
  width: 100%;
  color: $cfourWhite;
  font-size: 1.5rem;
}


.furniture {
  .no-touch nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li:hover, .touch nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li.hover {
    color: $cfourFurniturePrimaryColor !important;
  }
}


