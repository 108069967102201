nav .breadcrumb {
	padding: 5px 0;
	background: transparent;
	a {
		color: $cfourPrimaryColor;
	}
}

.furniture {
	nav .breadcrumb {
		.breadcrumb-item {
			//color: $cfourFurniturePrimaryColor;
			color: $cfourFurnitureTertiaryColor;
			&.active {
				color: $cfourGrayFont;
			}
		}
	}
}