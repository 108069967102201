.fashion-pro {
	.btn-outline-primary {
		color: $cfourPrimaryColor;
		border-color: $cfourSecondaryColor;
		&:focus,
		&.focus {
			color: $cfourPrimaryColor;
			background-color: darken($cfourSecondaryColor, 10%);
			border-color: darken($cfourSecondaryColor, 25%);
		}
		&:hover {
			color: $cfourPrimaryColor;
			background-color: darken($cfourSecondaryColor, 10%);
			border-color: darken($cfourSecondaryColor, 12%);
		}
		&:active,
		&.active,
		.open > &.dropdown-toggle {
			color: $cfourPrimaryColor;
			background-color: darken($cfourSecondaryColor, 10%);
			border-color: darken($cfourSecondaryColor, 12%);

			&:hover,
			&:focus,
			&.focus {
				color: $cfourPrimaryColor;
				background-color: darken($cfourSecondaryColor, 17%);
				border-color: darken($cfourSecondaryColor, 25%);
			}
		}
		&:active,
		&.active,
		.open > &.dropdown-toggle {
			background-image: none;
		}
		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus,
			&.focus {
				background-color: $cfourSecondaryColor;
				border-color: $cfourSecondaryColor;
			}
		}
	}

	.btn-primary {
		background-color: $cfourSecondaryColor;
		border-color: $cfourSecondaryColor;
		color: $cfourPrimaryColor;
		&:focus,
		&.focus {
			color: $cfourPrimaryColor;
			background-color: darken($cfourSecondaryColor, 10%);
			border-color: darken($cfourSecondaryColor, 25%);
		}
		&:hover {
			color: $cfourPrimaryColor;
			background-color: darken($cfourSecondaryColor, 10%);
			border-color: darken($cfourSecondaryColor, 12%);
		}
		&:active,
		&.active,
		.open > &.dropdown-toggle {
			color: $cfourPrimaryColor;
			background-color: darken($cfourSecondaryColor, 10%);
			border-color: darken($cfourSecondaryColor, 12%);

			&:hover,
			&:focus,
			&.focus {
				color: $cfourPrimaryColor;
				background-color: darken($cfourSecondaryColor, 17%);
				border-color: darken($cfourSecondaryColor, 25%);
			}
		}
		&:active,
		&.active,
		.open > &.dropdown-toggle {
			background-image: none;
		}
		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus,
			&.focus {
				background-color: $cfourSecondaryColor;
				border-color: $cfourSecondaryColor;
			}
		}
	}
}

.furniture {
	.btn-outline-primary {
		color: $cfourFurniturePrimaryColor;
		border-color: $cfourFurnitureSecondaryColor;
		&:focus,
		&.focus {
			color: $cfourFurniturePrimaryColor;
			background-color: darken($cfourFurnitureSecondaryColor, 10%);
			border-color: darken($cfourFurnitureSecondaryColor, 25%);
		}
		&:hover {
			color: $cfourFurniturePrimaryColor;
			background-color: darken($cfourFurnitureSecondaryColor, 10%);
			border-color: darken($cfourFurnitureSecondaryColor, 12%);
		}
		&:active,
		&.active,
		.open > &.dropdown-toggle {
			color: $cfourFurniturePrimaryColor;
			background-color: darken($cfourFurnitureSecondaryColor, 10%);
			border-color: darken($cfourFurnitureSecondaryColor, 12%);

			&:hover,
			&:focus,
			&.focus {
				color: $cfourFurniturePrimaryColor;
				background-color: darken($cfourFurnitureSecondaryColor, 17%);
				border-color: darken($cfourFurnitureSecondaryColor, 25%);
			}
		}
		&:active,
		&.active,
		.open > &.dropdown-toggle {
			background-image: none;
		}
		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus,
			&.focus {
				background-color: $cfourFurnitureSecondaryColor;
				border-color: $cfourFurnitureSecondaryColor;
			}
		}
	}

	.btn-primary {
		background-color: $cfourFurnitureSecondaryColor;
		border-color: $cfourFurnitureSecondaryColor;
		//color: $cfourFurniturePrimaryColor;
		color: $cfourWhite;
		&:focus,
		&.focus {
			color: $cfourFurniturePrimaryColor;
			background-color: darken($cfourFurnitureSecondaryColor, 10%);
			border-color: darken($cfourFurnitureSecondaryColor, 25%);
		}
		&:hover {
			//color: $cfourFurniturePrimaryColor;
			color: $cfourWhite;
			background-color: darken($cfourFurnitureSecondaryColor, 10%);
			border-color: darken($cfourFurnitureSecondaryColor, 12%);
		}
		&:active,
		&.active,
		.open > &.dropdown-toggle {
			color: $cfourFurniturePrimaryColor;
			background-color: darken($cfourFurnitureSecondaryColor, 10%);
			border-color: darken($cfourFurnitureSecondaryColor, 12%);

			&:hover,
			&:focus,
			&.focus {
				color: $cfourFurniturePrimaryColor;
				background-color: darken($cfourFurnitureSecondaryColor, 17%);
				border-color: darken($cfourFurnitureSecondaryColor, 25%);
			}
		}
		&:active,
		&.active,
		.open > &.dropdown-toggle {
			background-image: none;
		}
		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus,
			&.focus {
				background-color: $cfourFurnitureSecondaryColor;
				border-color: $cfourFurnitureSecondaryColor;
			}
		}
	}

}