.list-controls .list-controls-inner {
	background: $cfourSecondaryColor;
}
.cmp-paginator{
	.page-item {
		&.active {
			.page-link {
				background-color: $cfourSecondaryColor;
				border-color: $cfourSecondaryColor;
			}
		}
	}
}

.furniture {
	.list-controls .list-controls-inner {
		//background: $cfourFurnitureSecondaryColor;
		background: #e3e7eb;
	}
	.pagination{
		.page-item {
			&.active {
				.page-link {
					background-color: $cfourFurnitureSecondaryColor;
					border-color: $cfourFurnitureSecondaryColor;
				}
			}
		}
	}
}