.main-navbar-collapsable {
  background: $cfourLightGray;
  border-top: 1px solid $cfourSecondaryColor;
  border-bottom: 1px solid $cfourSecondaryColor;

  ul.mainmenu {
    color: $cfourPrimaryColor;
    text-align: center;
    //QUICKFIX temporär
    float: none !important;

    li {
      float: none;

      &:hover {
        background-color: transparent !important;
        color: $cfourPrimaryColor !important;
        & > a {
          color: $cfourPrimaryColor !important;
        }
      }

      & > ul {
        background: $cfourWhite;

        & > li {
          //border-top: 1px solid $cfourPrimaryColor;
          font-weight: bold;
          color: $cfourPrimaryColor;

          &:hover {
            //background: $cfourSecondaryColor !important;
            color: $cfourPrimaryColor !important;
          }
        }
      }
      a {
        color: $cfourPrimaryColor;
      }
    }

    li.ddown {
      .level1 {
        border: none;

        & > a {
          color: $cfourPrimaryColor !important;
          text-transform: uppercase;
          border-bottom: 1px solid;
          margin-bottom: 10px;
          width: 100%;
        }

      }

      .level2 {
        font-weight: normal;
        border: none;

        &:hover {
          background: $cfourWhite !important;
        }
      }
    }
  }
}

nav.navbar.megamenu {
  .main-navbar-collapsable {
    li.ddown {
      & > ul {
        border: 1px solid $cfourSecondaryColor;
      }
    }
  }
  .collapse {
    min-width: auto;
    width: 1200px;
    margin: auto;
    right: 0;
  }
}

.furniture, .fashion-pro {
  .normalmenu {
      ul {
        &.collapse {
            margin-top: 1px;
        }
      }
    }
}

nav.navbar.megamenu {
  @include media-breakpoint-up(lg) {
    position: relative;
  }

  .main-navbar-collapsable {

    @include media-breakpoint-up(lg) {
      ul.mainmenu {
        justify-content: center;

        > li {
          &:hover {
            color: $cfourPrimaryColor !important;
          }

          > ul.collapse {
            > li {

              &.level1 {
                > a {
                  color: $cfourPrimaryColor;
                }
              }

              a {
                color: $cfourPrimaryColor;

                &:hover {
                  color: $cfourPrimaryColor !important;
                }
              }
            }

          }

        }
      }
    }
  }
}

.mobile-navigation {
  color: $cfourPrimaryColor;
  background: $cfourWhite;

  ul.breadcrumb {
    background: $cfourSecondaryColor;
    color: $cfourWhite;
  }
}


.furniture {
  .main-navbar-collapsable {
    background: #dbd7cc;
    -webkit-box-shadow: inset 0 5px 10px 1px rgba(0,0,0,.3);
    box-shadow: inset 0 10px 40px -15px rgba(0,0,0,.3);
    border: 0;

    ul.mainmenu {
      color: $cfourFurniturePrimaryColor;

      li {
        float: none;

        &:hover {
          color: $cfourFurniturePrimaryColor !important;
        }

        & > ul {

          & > li {
            color: $cfourFurniturePrimaryColor;

            &:hover {
              color: $cfourFurniturePrimaryColor !important;
            }
          }
        }
        a {
          color: $cfourFurniturePrimaryColor;
        }
      }

      li.ddown {
        .level1 {
          & > a {
            color: $cfourFurniturePrimaryColor !important;
          }
        }
      }
    }
  }

  nav.navbar.megamenu {
    .main-navbar-collapsable {
      li.ddown {
        & > ul {
          border: 1px solid $cfourFurnitureSecondaryColor;
        }
      }
    }
  }

  nav.navbar.megamenu {
    .main-navbar-collapsable {

      @include media-breakpoint-up(lg) {
        ul.mainmenu {

          > li {
            &:hover {
              color: $cfourFurniturePrimaryColor !important;
            }

            > ul.collapse {
              > li {

                &.level1 {
                  > a {
                    color: $cfourFurniturePrimaryColor;
                  }
                }

                a {
                  color: $cfourFurniturePrimaryColor;

                  &:hover {
                    color: $cfourFurniturePrimaryColor !important;
                  }
                }
              }

            }

          }
        }
      }
    }
  }

  .mobile-navigation {
    color: $cfourFurniturePrimaryColor;

    ul.breadcrumb {
      background: $cfourFurnitureSecondaryColor;
    }
  }

}
