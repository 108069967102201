a {
	color: $cfourPrimaryColor;
}

#page-header {
	.top-bar {
		.navbar-navigation {
			@media (min-width: 991px) {
				padding: 0;
			}
		}
		.cfour-cachet {
			position: relative;
			.cachet {
				position: absolute;
				left: 0;
				z-index: 9999;
				top: 0;
				background: #fff;
				box-shadow: 0 3px 17px rgba(0,0,0,0.1);
				padding: 15px 0 15px 15px;
				width: auto;
				height: 110px;
				.image {
					width: 80px;
					height: 80px;
					background-size: cover !important;
					background-position: center !important;
					background-repeat: no-repeat !important;
					display: inline-block !important;
					margin-right: 15px;
				}
				img {
					border-style: none;
					width: 100px;
					padding-right: 15px;
				}
			}
		}
		.controls-list {
            .control-user {
				#login-change {
					& > .dropdown {
						.dropdown-menu {
							a {
								background-color: $cfourWhite;
							}
						}
					}
					& > .dropdown.open {
					  	a {
							background-color: $cfourWhite;
					  	}
					}
					@media (max-width: 767px) {
						a[href="#login"] span {
							display: none;
						}
						a[href="#registration"] span {
							display: none;
						}
					}
                }
            }
			.control-basket {
				position: relative;
				.toggle-basket-preview {
					background-color: $cfourWhite;
					&:hover {
						background-color: $cfourWhite;
					}
				}
				#basket-tooltip {
					position: absolute;
					z-index: 999;
					right: 20px;
					cursor: pointer;
					opacity: 1;
					-webkit-transition: opacity 0.4s;
					-moz-transition: opacity 0.4s;
					transition: opacity 0.4s;
					.tooltipArrow {
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 10px 10px 10px;
						float: right;
						margin-right: 10px;
					}
					.tooltipContent {
						padding: 4px 20px;
						margin-top: 10px;
						font-size: 16px;
						color: $cfourWhite;
						white-space: nowrap;
					}
				}
				#basket-tooltip.cfourFadeOut {
					opacity: 0;
				}
			}
            li{
              a{
                font-size: .9em;
                text-transform: uppercase;
              }
              a[aria-expanded="true"] {
                font-size: .9em;
                text-transform: uppercase;
              }
            }
			&>li{
				a{
					font-size: .9em;
					text-transform: uppercase;
					min-height: 30px;
					outline: none;
				}
                a[aria-expanded="true"] {
                  font-size: .9em;
                  text-transform: uppercase;
                }
			}
		}
	}
	.navbar {
		background: $cfourWhite;
		border-bottom: none;
		flex-direction: column;
		align-items: normal;
		.brand-wrapper {
			position: relative;
			transform: none;
			text-align: center;
			display: flex;
			.navbar-brand {
				display: block;
				margin: 0 auto;
				max-width: 200px;
				padding: 20px 0;
				@media (max-width: 767px) {
					padding: 10px 0;
				}
				img {
					max-height: none;
				}
			}
			.navbar-toggler {
				position: absolute;
				margin-top: 2.5rem;
				right: 1rem;
			}
		}
	}
	.sb-header {
		.brand-wrapper {
			width: 100%;
		}
	}
}

@media (max-width: 354px) {
	.control-basket i{
		display: none;
	}
	.control-wish-list .badge-right{
		display: none;
	}
}

@include media-breakpoint-down(md) {
		#page-header {
			.top-bar {
				.cfour-cachet {
					.cachet {
						top: 0px;
						padding: 7px 0 7px 7px;
						width: auto;
						height: 55px;
						.image {
							width: 40px;
							height: 40px;
						}

						img {
							border-style: none;
							width: 40px;
							padding-right: 10px;
						}
					}
				}
			}
		}
}

@media(max-width:991px){

	.hasCachetOffset #searchBox {
		top:85px;
	}
	div:not(.top-bar) > .page-header > div > .cmp.cmp-search-box .search-box-inner {
		margin: 0 auto;
		justify-content: end;
	}
	.search-box-shadow-frame {
		padding: 5px;
		background: #e9ecef;
	}
}
@media(min-width:992px){
	div:not(.top-bar) > .page-header > div > .cmp.cmp-search-box .search-box-inner {
		margin: 0 auto;
		justify-content: end;
	}
	.search-box-shadow-frame {
		max-width: 100%;
		padding: 5px;
		background: #e9ecef;
	}
	.hasCachetOffset .search-box-shadow-frame {
		max-width: 50%;
	}
}

@media (max-width: 575px) {
	#page-header {
		.top-bar {
			.cfour-cachet {
				.cachet {
					position: relative;
				}
			}
		}
	}
}


.furniture {
	a {
		color: $cfourFurniturePrimaryColor;
	}
		#page-header {
			.top-bar {
				background: $cfourFurniturePrimaryColor;

				.controls-list {
					.control-user {
						#login-change {
							& > .dropdown {
								.dropdown-menu {
									a {
										color: $cfourFurniturePrimaryColor;
									}
								}
							}

							& > .dropdown.open {
								a {
									color: $cfourFurniturePrimaryColor;
								}
							}
						}
					}

					.control-languages {
						a[aria-expanded="true"] {
							background: transparent;
						}
					}

					.control-search {
						a[aria-expanded="true"] {
							background: $cfourFurnitureSecondaryColor;
						}
					}

					.control-wish-list {
						a {
							i.fa-heart {
								color: $cfourFurniturePrimaryColor;
							}
						}
					}

					.control-basket {
						.toggle-basket-preview {
							color: $cfourWhite;
							background: $cfourFurnitureTertiaryColor;
						}

						#basket-tooltip {
							.tooltipArrow {
								border-color: transparent transparent $cfourFurniturePrimaryColor transparent;
							}

							.tooltipContent {
								background: $cfourFurniturePrimaryColor;
							}
						}
					}

					li {
						a {
							&:hover {
								background: $cfourFurnitureSecondaryColor;
								color: $cfourFurniturePrimaryColor;
							}
						}

						a[aria-expanded="true"] {
							background: $cfourFurnitureSecondaryColor;
							color: $cfourFurniturePrimaryColor;
						}
					}

					& > li {
						a {
							&:hover {
								color: $cfourWhite;
								background: transparent;
							}
						}

						a[aria-expanded="true"] {
							background: $cfourFurnitureSecondaryColor;
							color: $cfourFurniturePrimaryColor
						}
					}
				}
			}
	}


	@media(min-width:992px){
		.cmp.cmp-search-box {
			.search-box-shadow-frame {
				background: $cfourFurniturePrimaryColor;
			}
			.search-submit {
				background: $cfourFurniturePrimaryColor;
				color: $cfourWhite !important;
			}
		}
	}
}

.fashion-pro {
	a {
		color: $cfourPrimaryColor;
	}
		#page-header {
			.top-bar {
				background: $cfourSecondaryColor;

				.controls-list {
					.control-user {
						#login-change {
							& > .dropdown {
								.dropdown-menu {
									a {
										color: $cfourPrimaryColor;
									}
								}
							}

							& > .dropdown.open {
								a {
									color: $cfourPrimaryColor;
								}
							}
						}
					}

					.control-languages {
						a[aria-expanded="true"] {
							background: $cfourSecondaryColor;
						}
					}

					.control-search {
						a[aria-expanded="true"] {
							background: $cfourSecondaryColor;
						}
					}

					.control-wish-list {
						a {
							i.fa-heart {
								color: $cfourPrimaryColor;
							}
						}
					}

					.control-basket {
						.toggle-basket-preview {
							color: $cfourPrimaryColor;
						}

						#basket-tooltip {
							.tooltipArrow {
								border-color: transparent transparent $cfourPrimaryColor transparent;
							}

							.tooltipContent {
								background: $cfourPrimaryColor;
							}
						}
					}

					li {
						a {
							color: $cfourPrimaryColor;

							&:hover {
								background: $cfourSecondaryColor;
								color: $cfourPrimaryColor;
							}
						}

						a[aria-expanded="true"] {
							background: $cfourSecondaryColor;
							color: $cfourPrimaryColor;
						}
					}

					& > li {
						a {
							color: $cfourPrimaryColor;

							&:hover {
								background: $cfourSecondaryColor;
								color: $cfourPrimaryColor;
							}
						}

						a[aria-expanded="true"] {
							background: $cfourSecondaryColor;
							color: $cfourPrimaryColor
						}
					}
				}
			}
		}
}
